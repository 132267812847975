.heading {
  font-size: 16px;
  line-height: 24px;
  color: var(--tm-kr-color-neutrals-grey-100);
}

/* Types */
.bold {
  font-weight: 700;
}
.semibold {
  font-weight: 600;
}
