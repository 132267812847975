.wrapper {
  background-color: var(--tm-stencil-color-base-dark);
  width: fit-content;
  height: 100%;
  border-radius: 16px 0 0 16px;
  padding: 32px 10px;
}
.item {
  background-color: var(--tm-stencil-color-tertiary-1);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  margin-bottom: 12px;
  cursor: pointer;
}
