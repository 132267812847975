.wrapper {
  width: 100%;
  height: 48px;
  display: flex;
  border-width: 1px;
  border-style: solid;
  border-color: var(--tm-kr-color-neutrals-grey-20);
  border-radius: var(--tm-kr-border-radius-m);
  /* padding: var(--tm-kr-spacing-4xs) var(--tm-kr-spacing-2xs); */
}

.wrapper[data-status='success'] {
  border-color: var(--tm-kr-color-semantic-success-100);
}
.wrapper[data-status='error'] {
  border-color: var(--tm-kr-color-semantic-error-100);
}
.wrapper[data-status='warning'] {
  border-color: var(--tm-kr-color-semantic-caution-100);
}

/* Input field  */
.input {
  width: 50%;
  height: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  /* background-color: var(--tm-kr-color-neutrals-grey-20); */
  border-width: 1px;
  border-style: none;
  border-right: 1px solid var(--tm-kr-color-neutrals-grey-20);
  padding: var(--tm-kr-spacing-4xs) var(--tm-kr-spacing-2xs);
  border-radius: var(--tm-kr-border-radius-m) 0 0 var(--tm-kr-border-radius-m);
  color: var(--tm-kr-color-neutrals-grey-100);
}

/* Dropdown field */
.dropdown {
  width: 50%;
  height: 100%;
  padding: var(--tm-kr-spacing-2xs) var(--tm-kr-spacing-2xs);
  cursor: pointer;
}
.placeholderWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.placeholder {
  color: var(--tm-kr-color-neutrals-grey-50);
  font-size: 14px;
}
.dropdownSelectedValue {
  color: var(--tm-kr-color-neutrals-grey-100);
  font-size: 14px;
}

.optionsBox {
  width: calc(100% + 24px);
  min-width: calc(100% + 24px);
  max-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--tm-kr-spacing-3xs);
  overflow-y: scroll;
  background: var(--tm-kr-color-neutrals-grey-10);
  border: 1px solid var(--tm-kr-color-neutrals-grey-20);
  border-radius: var(--tm-kr-border-radius-m);
  margin-left: -12px;
  margin-top: 14px;
  position: relative;
  z-index: 1;
}
.option {
  width: 100%;
  height: 48px;
  padding: var(--tm-kr-spacing-xs) var(--tm-kr-spacing-2xs);
  cursor: pointer;
  font-size: 14px;
}
.option:hover {
  background: var(--tm-kr-color-neutrals-grey-20);
  border-radius: var(--tm-kr-border-radius-s);
}
