.wrapper {
  align-items: center;
  text-align: center;
}
.icon {
  width: 200px;
  max-width: 80%;
}
.label {
  margin-top: 10px;
}
.desc {
  margin-top: 4px;
}
.button {
  margin-top: 10px;
  white-space: nowrap;
  width: fit-content;
}

@media only screen and (min-width: 1024px) {
  .wrapper {
    grid-template-columns: auto auto auto auto;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
  .icon {
    width: 250px;
    max-width: 80%;
  }
  .label {
    margin-top: 20px;
  }
  .desc {
    margin-top: 6px;
  }
  .button {
    margin-top: 14px;
  }
}
