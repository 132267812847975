.wrapper {
  padding: 16px;
  position: relative;
}

.titleContainer {
  display: flex;
  margin-bottom: 4px;
  color: var(--tm-color-gy-1);
  font-size: 16px;
  font-weight: 400;
}

.astrik {
  color: #ff5e5e;
  padding-left: 5px;
}

.datePickerWrapper {
  width: 100%;
  margin-bottom: 0px;
}

.tooltip {
  margin-left: 10px;
}

@media (--large-devices) {
  .titleContainer {
    font-size: 14px;
  }
}
