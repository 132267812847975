.heading {
  font-size: 20px;
  line-height: 28px;
  color: var(--tm-kr-color-neutrals-grey-100);
}

/* Types */
.bold {
  font-weight: 700;
}
.semibold {
  font-weight: 600;
}

@media only screen and (min-width: 1024px) {
  .heading {
    font-size: 24px;
    line-height: 32px;
  }
}
