.sidebarOuterWrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--tm-kr-color-base-light-100);
  z-index: 12;
}
.sidebarWrapper {
  width: 75%;
  height: 100%;
  background-color: var(--tm-kr-color-base-light-100);
  overflow-x: hidden;
  overflow-y: auto;
  padding: var(--tm-kr-spacing-xs) 0;
}

/* Sidebar Item  */
.sidebarItemLink {
  display: block;
  margin: var(--tm-kr-spacing-xs) 0;
  padding: 10px 24px;
}
.sidebarItemLinkSelected {
  border-left: 5px solid var(--tm-kr-color-primary-100);
}

.sidebarItemLinkInner {
  display: flex;
  align-items: center;
}
.sidebarItemLabel {
  margin-left: 16px;
}

/* TODO: juggad */
.sidebarItemLinkInner > span::before {
  color: var(--tm-kr-color-neutrals-grey-60);
}

.backToDepartmentsWrapper {
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
}
.backToDepartmentsLabel {
  margin-left: 6px;
}

@media only screen and (min-width: 1024px) {
  .sidebarOuterWrapper {
    position: sticky;
    width: 100%;
    height: 100%;
    border-right: 1px solid var(--tm-kr-color-neutrals-grey-30);
  }
  .sidebarWrapper {
    width: 100%;
  }
}
