.formOuterWrapper {
  padding: var(--tm-kr-spacing-s);
}
.collapseCard {
  margin-top: var(--tm-kr-spacing-s);
}
.formWrapper {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-column-gap: 32px;
  grid-row-gap: 24px;
}
.programHeading {
  color: var(--tm-kr-color-neutrals-grey-100);
  margin: var(--tm-kr-spacing-xs) 0;
}
.programFormWrapper {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-column-gap: 32px;
}
.programFormOuterWrapper .programFormWrapper:not(:first-child) {
  margin-top: var(--tm-kr-spacing-s);
}
.addButtonWrapper {
  padding: 0px;
}
.submitButtonWrapper {
  width: 100%;
  margin-top: var(--tm-kr-spacing-2xl);
  padding: var(--tm-kr-spacing-s);
}

@media only screen and (min-width: 1024px) {
  .formOuterWrapper {
    flex-grow: 1;
    padding: var(--tm-kr-spacing-l);
    overflow-y: scroll;
  }
  .formWrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .programFormWrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .submitButtonWrapper {
    padding: var(--tm-kr-spacing-xs) var(--tm-kr-spacing-l);
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid var(--tm-kr-color-neutrals-grey-30);
    margin-top: 0;
  }
}
