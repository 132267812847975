.formWrapper .inputWrapper:not(:last-child) {
  margin-bottom: 32px;
}
.heading {
  margin-bottom: 30px;
}
.submitButton {
  margin-top: 40px;
  width: 100% !important;
}

@media only screen and (min-width: 1024px) {
}
