.card {
  background-color: var(--tm-kr-color-neutrals-grey-10);
}

/* Sizes */
.small {
  border-radius: var(--tm-kr-border-radius-s);
}
.medium {
  border-radius: var(--tm-kr-border-radius-m);
  padding: var(--tm-kr-spacing-s) var(--tm-kr-spacing-xxs);
}
.large {
  border-radius: var(--tm-kr-border-radius-l);
  padding: var(--tm-kr-spacing-m) var(--tm-kr-spacing-xs);
}

@media only screen and (min-width: 1024px) {
  /* Sizes */
  .small {
    border-radius: var(--tm-kr-border-radius-s);
  }
  .medium {
    border-radius: var(--tm-kr-border-radius-m);
    padding: var(--tm-kr-spacing-xl) var(--tm-kr-spacing-m);
  }
  .large {
    border-radius: var(--tm-kr-border-radius-l);
    padding: var(--tm-kr-spacing-2xl) var(--tm-kr-spacing-l);
  }
}
