.inputWrapper {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--tm-kr-color-neutrals-grey-20);
  border-radius: var(--tm-kr-border-radius-m);
  border-width: 1px;
  border-style: solid;
  border-color: var(--tm-kr-color-neutrals-grey-20);
}
.input {
  width: calc(100% - 40px);
  height: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: var(--tm-kr-color-neutrals-grey-20);
  border-radius: var(--tm-kr-border-radius-m);
  border-width: 0px;
  padding: var(--tm-kr-spacing-4xs) var(--tm-kr-spacing-2xs);
  outline: none;
  font-size: 14px;
  color: var(--tm-kr-color-neutrals-grey-100);
}
.showButtonWrapper {
  cursor: pointer;
  margin-right: var(--tm-kr-spacing-2xs);
}

.inputWrapper[data-status='success'] {
  border-color: var(--tm-stencil-color-success-2);
}
.inputWrapper[data-status='error'] {
  border-color: var(--tm-kr-color-semantic-error-100);
}
.inputWrapper[data-status='warning'] {
  border-color: var(--tm-stencil-color-warning-1);
}
