.formOuterWrapper {
  padding: 0 var(--tm-kr-spacing-s) 0 var(--tm-kr-spacing-s);
}
.formWrapper {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-column-gap: var(--tm-kr-spacing-l);
  grid-row-gap: var(--tm-kr-spacing-m);
}

.submitButtonWrapper {
  width: 100%;
  margin-top: var(--tm-kr-spacing-s);
  padding: var(--tm-kr-spacing-s);
}
.submitButton {
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .formOuterWrapper {
    flex-grow: 1;
    padding: var(--tm-kr-spacing-l);
  }
  .formWrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .submitButtonWrapper {
    padding: var(--tm-kr-spacing-xs) var(--tm-kr-spacing-l);
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid var(--tm-kr-color-neutrals-grey-30);
    margin-top: 0;
  }

  .submitButton {
    width: fit-content;
  }
}
