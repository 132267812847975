.wrapper {
  display: inline-block;
  position: relative;
}

.wrapper :global(.react-datepicker__current-month) {
  display: none;
}

.wrapper > div {
  position: absolute;
  z-index: 1;
}

.textInputBox {
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  color: var(--tm-color-text-primary);
  border-radius: var(--tm-input-border-radius);
  border: 1px solid var(--tm-color-dark-gray);
  outline: none;
  padding: 14px 12px;
  width: 100%;
  height: 48px;
}

.textInputBox.open {
  border-color: var(--tm-input-focused-border-color);
}

.fitContentWidth {
  width: fit-content;
}
