.heading {
  margin-bottom: var(--tm-kr-spacing-xs);
}
.formWrapper .inputWrapper:not(:last-child) {
  margin-bottom: var(--tm-kr-spacing-m);
}
.forgotPassword {
  padding: 0px !important;
}
.termsPrivacyText {
  text-align: center;
  margin-top: var(--tm-kr-spacing-xs);
}

.submitButton {
  margin-top: var(--tm-kr-spacing-m);
}
