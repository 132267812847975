.wrapper {
  display: flex;
  height: 40px;
  padding: 0px var(--tm-kr-spacing-xs);
  align-items: center;
  border-radius: var(--tm-kr-border-radius-m);
  background-color: var(--tm-kr-color-neutrals-grey-20);
}
.searchIconWrapper {
  margin-top: var(--tm-kr-spacing-4xs);
  margin-right: var(--tm-kr-spacing-4xs);
}
.input {
  width: 100%;
  outline: none;
  background-color: var(--tm-kr-color-neutrals-grey-20);
  border: none;
  font-size: 14px;
  color: var(--tm-kr-color-neutrals-grey-100);
}
.clearIconWrapper {
  margin-top: var(--tm-kr-spacing-4xs);
  margin-right: var(--tm-kr-spacing-4xs);
  cursor: pointer;
}
