.headingWrapper {
  width: 100%;
  padding: var(--tm-kr-spacing-s);
}

@media only screen and (min-width: 1024px) {
  .headingWrapper {
    padding: var(--tm-kr-spacing-l);
    border-bottom: 1px solid var(--tm-kr-color-neutrals-grey-30);
  }
}
