.label {
  display: flex;
  margin-bottom: var(--tm-kr-spacing-4xs);
  color: var(--tm-kr-color-neutrals-grey-70);
  font-size: 14px;
  font-weight: 400;
}
.astrik {
  color: var(--tm-kr-color-semantic-error-100);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--tm-kr-color-neutrals-grey-50);
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--tm-kr-color-neutrals-grey-50);
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--tm-kr-color-neutrals-grey-50);
}

.infoText {
  font-size: 12px;
  font-weight: 400;
  margin-top: var(--tm-kr-spacing-4xs);
}

.error {
  color: var(--tm-kr-color-semantic-error-100);
}
.success {
  color: var(--tm-kr-color-semantic-success-100);
}
.warning {
  color: var(--tm-kr-color-semantic-caution-100);
}
