.navbarWrapper {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
}

/* Left Con */
.leftWrapper {
  display: flex;
  align-items: center;
}
.burgerIconWrapper {
  margin-right: 5px;
}
.instituteWrapper {
  display: flex;
  align-items: center;
}
.instituteLogo {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.instituteInfoWrapper {
  margin-left: 10px;
  max-width: 150px;
}
.instituteInfoWrapper > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.instituteId {
  margin-top: 4px;
}

/* Right Con */
.rightWrapper {
  display: flex;
  align-items: center;
}
.helpBtnWrapper {
  margin-right: 10px;
  cursor: pointer;
}
.userInfoWrapper {
  display: flex;
  align-items: center;
}
.userImage {
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.userName {
  display: none;
  margin-left: 10px;
}

@media only screen and (min-width: 1024px) {
  .navbarWrapper {
    padding: 12px 24px;
  }

  /* Left Con */
  .instituteLogo {
    width: 45px;
    height: 45px;
  }
  .burgerIconWrapper {
    display: none;
  }

  /* Right Con */
  .helpBtnWrapper {
    margin-right: 20px;
  }
  .userImage {
    width: 40px;
    height: 40px;
  }
  .userName {
    display: block;
  }
}
