.heading {
}
.para {
  margin-top: 3px;
}
.resendWrapper {
  margin: 15px 0 40px 0;
}
.resendBtn {
  cursor: pointer;
}
.submitButton {
  margin-top: 40px;
  width: 100% !important;
}

@media only screen and (min-width: 1024px) {
}
