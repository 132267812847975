.wrapper {
  display: flex;
  justify-content: space-between;
  padding: var(--tm-kr-spacing-2xs) var(--tm-kr-spacing-xs);
  background-color: var(--tm-kr-color-base-light-100);
  border-bottom: 1px solid var(--tm-kr-color-neutrals-grey-30);
}

/* Left Con */
.leftWrapper {
  display: flex;
  align-items: center;
}
.burgerIconWrapper {
  margin-right: var(--tm-kr-spacing-2xs);
}
.instituteLogo {
  width: 145px;
  height: 50px;
  object-fit: cover;
}

/* Right Con */
.rightWrapper {
  display: flex;
  align-items: center;
}
.notification {
  margin-right: var(--tm-kr-spacing-2xs);
  cursor: pointer;
}

.userInfoWrapper {
  display: flex;
  align-items: center;
}
.userImage {
  width: 34px;
  height: 34px;
  object-fit: cover;
}

@media only screen and (min-width: 1024px) {
  .navbarWrapper {
    padding: var(--tm-kr-spacing-xs) var(--tm-kr-spacing-s);
  }

  /* Left Con */
  .instituteLogo {
    width: 145px;
    height: 50px;
  }
  .burgerIconWrapper {
    display: none;
  }

  /* Right Con */
  .notification {
    margin-right: var(--tm-kr-spacing-s);
  }
  .userImage {
    width: 40px;
    height: 40px;
  }
}
