.emptyWrapper {
  margin-top: 60px;
}
.subHeaderWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: var(--tm-kr-spacing-xs) 0px;
}
.subHeaderSearchbarWrapper {
  width: 500px;
}

.listWrapper {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  grid-column-gap: var(--tm-kr-spacing-xs);
  grid-row-gap: var(--tm-kr-spacing-xs);
  margin-top: var(--tm-kr-spacing-xs);
}
.listItem {
  background-color: var(--tm-kr-color-neutrals-grey-20);
  padding: 0px;
}
.firstSectionWrapper {
  display: flex;
  padding: var(--tm-kr-spacing-xs);
}
.itemImg {
  width: 90px;
  height: 90px;
  border-radius: var(--tm-kr-border-radius-m);
  object-fit: cover;
  margin-right: var(--tm-kr-spacing-2xs);
}
.hodWrapper {
  display: flex;
  align-items: center;
  margin: var(--tm-kr-spacing-3xs) 0;
}
.icon {
  margin-right: var(--tm-kr-spacing-4xs);
}
.studentWrapper {
  display: flex;
  align-items: center;
}

.secondSectionWrapper {
  border-top: 1px solid var(--tm-kr-color-neutrals-grey-30);
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  padding: var(--tm-kr-spacing-xs);
}
.secondSectionItem {
  display: flex;
  align-items: center;
}
.secondSectionItemIconWrapper {
  margin-right: var(--tm-kr-spacing-3xs);
}

@media only screen and (min-width: 1024px) {
  /* .subHeaderWrapper {
    padding: 24px 0px;
  }
  .subHeaderSearchbarWrapper {
    width: 400px;
  }
  .addAction {
    width: fit-content;
    margin: 0px;
  } */

  .listWrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
