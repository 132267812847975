/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v135/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2)
    format('woff2');
}

.materialIcons {
  font-family: 'Material Icons' !important;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

/* Sizes */
.materialIcons[data-size='xs'] {
  font-size: 16px;
}
.materialIcons[data-size='s'] {
  font-size: 20px;
}
.materialIcons[data-size='m'] {
  font-size: 24px;
}
.materialIcons[data-size='l'] {
  font-size: 32px;
}

/* Colors */
.materialIcons[data-color='primary'] {
  color: var(--tm-kr-color-primary-100);
}
.materialIcons[data-color='darkGrey'] {
  color: var(--tm-kr-color-neutrals-grey-100);
}
.materialIcons[data-color='lightGrey'] {
  color: var(--tm-kr-color-neutrals-grey-60);
}
.materialIcons[data-color='white'] {
  color: var(--tm-kr-color-neutrals-grey-10);
}
.materialIcons[data-color='error'] {
  color: var(--tm-kr-color-semantic-error-100);
}
.materialIcons[data-color='caution'] {
  color: var(--tm-kr-color-semantic-caution-100);
}
.materialIcons[data-color='success'] {
  color: var(--tm-kr-color-semantic-success-100);
}
.materialIcons[data-color='biloba'] {
  color: var(--tm-kr-color-pastels-biloba-100);
}
.materialIcons[data-color='seapink'] {
  color: var(--tm-kr-color-pastels-seapink-100);
}
