.wrapper {
  width: 100%;
}
.mobileWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobileStepsItem {
  border-radius: var(--tm-kr-border-radius-m);
  padding: var(--tm-kr-spacing-4xs) var(--tm-kr-spacing-m);
}
.mobileStepsItem[data-color='success'],
.mobileStepsItem[data-color='active'] {
  background-color: var(--tm-kr-color-semantic-success-100);
  color: var(--tm-kr-color-neutrals-grey-10);
}
.mobileStepsItem[data-color='inactive'] {
  background-color: var(--tm-kr-color-neutrals-grey-50);
  color: var(--tm-kr-color-neutrals-grey-100);
}
.mobileBetweenStepsLine {
  height: 2px;
  background-color: var(--tm-kr-color-neutrals-grey-10);
  display: flex;
  flex-grow: 1;
}

.laptopWrapper {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .mobileWrapper {
    display: none;
  }

  .laptopWrapper {
    display: block;
  }
  .laptopStepsItem {
    width: 100%;
    display: flex;
  }

  /* Progress Wrapper */
  .progressWrapper {
    margin-right: var(--tm-kr-spacing-3xs);
  }
  .progressCircle {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    margin-top: 2px;
  }
  .progressCircle[data-color='success'] {
    background-color: var(--tm-kr-color-semantic-success-100);
  }
  .progressCircle[data-color='active'] {
    background-color: var(--tm-kr-color-semantic-caution-100);
  }
  .progressCircle[data-color='inactive'] {
    background-color: var(--tm-kr-color-neutrals-grey-10);
  }
  .progressLine {
    width: 0px;
    border-left: 1px dashed var(--tm-kr-color-neutrals-grey-10);
    height: 100%;
    margin: 0 auto;
  }

  /* Data Wrapper */
  .dataWrapper {
    width: 100%;
    margin-bottom: var(--tm-kr-spacing-s);
  }
  .dataWrapper[data-color='inactive'] {
    opacity: 0.7;
  }
  .stepNum {
    color: var(--tm-kr-color-neutrals-grey-10);
    text-transform: uppercase;
  }

  .stepBox {
    width: 100%;
    background-color: var(--tm-kr-color-neutrals-grey-10);
    margin-top: var(--tm-kr-spacing-4xs);
    border-radius: var(--tm-kr-border-radius-l);
    padding: var(--tm-kr-spacing-xs);
    display: flex;
    align-items: center;
  }

  .iconBox {
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--tm-kr-border-radius-l);
    margin-right: var(--tm-kr-spacing-2xs);
    background-color: var(--tm-kr-color-neutrals-grey-50);
    flex-shrink: 0;
  }
  .iconBox[data-color='success'] {
    background-color: var(--tm-kr-color-semantic-success-100);
  }
}
