.wrapper {
  width: 100%;
  border-radius: var(--tm-kr-border-radius-m);
  border: 1px solid var(--tm-kr-color-neutrals-grey-30);
}
.headerWrapper {
  width: 100%;
  padding: var(--tm-kr-spacing-xs);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.headerWrapper[data-status='false'] {
  border-bottom: 1px solid var(--tm-kr-color-neutrals-grey-30);
  background-color: var(--tm-kr-color-neutrals-grey-20);
}

.bodyWrapper {
  padding: var(--tm-kr-spacing-xs);
}
