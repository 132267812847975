.wrapper {
  width: 100%;
  background-color: var(--tm-color-gy-3);
  border-radius: 24px 24px 0 0;
  padding: 20px 16px;
}
.headingCon {
  display: flex;
  align-items: center;
}
.headingLabel {
  margin-left: 4px;
}

@media only screen and (min-width: 1024px) {
  .wrapper {
    border-radius: 24px 0 0 0;
    padding: 40px;
  }
}
