.emptyWrapper {
  margin-top: 60px;
}

.sessionListManage {
  cursor: pointer;
}

.subHeaderWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
}
.subHeaderSearchbarWrapper {
  width: 100%;
}
.addAction {
  width: 100%;
  text-align: right;
  margin-top: 16px;
  cursor: pointer;
}

@media only screen and (min-width: 1024px) {
  .subHeaderWrapper {
    padding: 24px 16px;
  }
  .subHeaderSearchbarWrapper {
    width: 400px;
  }
  .addAction {
    width: fit-content;
    margin: 0px;
  }
}
