.dropdown {
  width: 100%;
  height: 48px;
  background-color: var(--tm-kr-color-neutrals-grey-40);
  border-color: var(--tm-kr-color-neutrals-grey-40);
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  padding: 11px 12px;
  outline: none;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}
.placeholderWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.placeholder {
  color: var(--tm-kr-color-neutrals-grey-60);
}

.errorBorder {
  border-color: var(--tm-color-error);
}

.fitContent {
  width: fit-content;
}

.disabled {
  color: var(--tm-color-placeholder);
}

.optionsBox {
  width: calc(100% + 24px);
  max-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  overflow-y: scroll;
  background: var(--tm-kr-color-base-light-100);
  border: 1px solid var(--tm-kr-color-neutrals-grey-40);
  border-radius: 8px;
  margin-left: -12px;
  margin-top: 14px;
  position: relative;
  z-index: 1;
}
.option {
  width: 100%;
  height: 48px;
  padding: 15px 10px;
  cursor: pointer;
}
.option:hover {
  background: var(--tm-kr-color-neutrals-grey-40);
  border-radius: 4px;
}

@media (--large-devices) {
  .dropdown {
    font-size: 16px;
    line-height: 20px;
  }
}
