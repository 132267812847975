.loginWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: var(--tm-kr-color-base-dark-100);
}
.loginCard {
  width: 90%;
  max-height: 90%;
}
.logoWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: var(--tm-kr-spacing-m);
}
.logo {
  width: 160px;
}
.errorText {
  background-color: var(--tm-kr-color-semantic-error-10);
  padding: var(--tm-kr-spacing-2xs);
  text-align: center;
  margin: 0 -16px var(--tm-kr-spacing-m) -16px;
}

@media only screen and (min-width: 1024px) {
  .loginCard {
    width: 500px;
  }
  .logo {
    width: 200px;
  }
  .errorText {
    margin: 0 -32px var(--tm-kr-spacing-m) -32px;
  }
}
