.input {
  width: 100%;
  height: 48px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: var(--tm-kr-color-neutrals-grey-20);
  border-width: 1px;
  border-style: solid;
  border-color: var(--tm-kr-color-neutrals-grey-20);
  border-radius: var(--tm-kr-border-radius-m);
  padding: var(--tm-kr-spacing-4xs) var(--tm-kr-spacing-2xs);
  outline: none;
  font-size: 14px;
  color: var(--tm-kr-color-neutrals-grey-100);
}

.input[data-status='success'] {
  border-color: var(--tm-kr-color-semantic-success-100);
}
.input[data-status='error'] {
  border-color: var(--tm-kr-color-semantic-error-100);
}
.input[data-status='warning'] {
  border-color: var(--tm-kr-color-semantic-caution-100);
}
