.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--tm-kr-border-radius-m);
}

/* Sizes */
.small {
  width: 34px;
  height: 34px;
}
.medium {
  width: 40px;
  height: 40px;
}
.large {
  width: 50px;
  height: 50px;
}

/* Colors */
.bilobaLight {
  background-color: var(--tm-kr-color-pastels-biloba-10);
}
.seapinkLight {
  background-color: var(--tm-kr-color-pastels-seapink-10);
}
.grey20 {
  background-color: var(--tm-kr-color-neutrals-grey-20);
}
