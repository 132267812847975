.submitButton {
  margin-top: 20px;
  width: 100%;
  height: 40px;
}

.inputWrapper > div {
  padding: 0 0 24px 0;
}

.sectionFieldsWrapper {
  background-color: var(--tm-color-gy-3);
  padding: 16px;
  border-radius: 16px;
}

.sectionFieldsInnerWrapper {
  margin-top: 20px;
}

.sectionInputItemWrapper {
  display: flex;
  justify-content: space-between;
}

.sectionInputWrapper {
  width: 90%;
}

.addSectionBtn {
  cursor: pointer;
}

.crossIcon {
  margin-top: 7px;
  cursor: pointer;
}
