.wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 12px;
  grid-row-gap: 12px;
}
.item {
  width: 100%;
  background-color: var(--tm-color-wh-1);
  border-radius: 16px;
  padding: 12px;
}

@media only screen and (min-width: 1024px) {
  .wrapper {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
}
