.para {
  font-size: 12px;
  line-height: 16px;
  color: var(--tm-kr-color-neutrals-grey-70);
}

/* Types */
.light {
  font-weight: 300;
}
.regular {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
