.outerWrapper {
  display: flex;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  margin: -24px;
  flex-grow: 0;
}
.wrapper {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--tm-stencil-color-base-dark);
}
.leftWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  padding: var(--tm-kr-spacing-3xl) var(--tm-kr-spacing-xs);
}
.rightWrapper {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: var(--tm-kr-color-base-light-100);
  border-radius: var(--tm-kr-border-radius-l) var(--tm-kr-border-radius-l) 0 0;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 1024px) {
  .outerWrapper {
    width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
    margin: 0px;
  }
  .wrapper {
    width: 1100px;
    border-radius: var(--tm-kr-border-radius-l);
    height: 100%;
  }
  .leftWrapper {
    width: 30%;
    border-radius: var(--tm-kr-border-radius-l) 0 0 var(--tm-kr-border-radius-l);
    display: block;
    padding: var(--tm-kr-spacing-m);
  }
  .rightWrapper {
    width: 70%;
    max-height: 100%;
    border-radius: 0 var(--tm-kr-border-radius-l) var(--tm-kr-border-radius-l) 0;
  }
}
