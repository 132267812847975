.sidebarOuterWrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(31, 57, 101, 0.5843137254901961);
  z-index: 12;
}
.sidebarWrapper {
  width: 75%;
  height: 100%;
  /* background-color: var(--tm-color-gy-4); */
  background-color: var(--tm-color-wh-1);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 16px;
}

/* Sidebar Item  */
.sidebarItemLink {
  display: block;
  padding: 16px;
  border-radius: 16px;
}
.sidebarItemLinkInner {
  display: flex;
  align-items: center;
}
.sidebarItemLabel {
  margin-left: 6px;
}

.backToDepartmentsWrapper {
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
}
.backToDepartmentsLabel {
  margin-left: 6px;
}

@media only screen and (min-width: 1024px) {
  .sidebarOuterWrapper {
    position: sticky;
    width: 100%;
    height: 100%;
  }
  .sidebarWrapper {
    width: 100%;
  }
}
