.heading {
  margin-bottom: var(--tm-kr-spacing-xs);
}
.submitButton {
  margin-top: var(--tm-kr-spacing-m);
}
.backBtn {
  margin-top: var(--tm-kr-spacing-s);
  text-align: center;
}
