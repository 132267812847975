.emptyWrapper {
  margin-top: 60px;
}
.addProgramBtnWrapper {
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
}
.item {
  width: 100%;
  background-color: var(--tm-color-wh-1);
  border-radius: 16px;
  margin-bottom: 20px;
  padding: 16px;
}
.programBatchWrapper {
  margin-top: 20px;
  width: 100%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.programBatchWrapper::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.programBatchWrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-tap-highlight-color: transparent;
}

.batchItem {
  background-color: var(--tm-color-gy-3);
  border-radius: 16px;
  width: 250px;
  min-width: 250px;
  min-height: 130px;
  padding: 16px;
  margin-right: 20px;
  white-space: initial;
  display: inline-block;
}
.emptyBatchItem {
  border: 1px dotted var(--tm-color-gy-1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dateWrapper {
  margin-top: 4px;
}
