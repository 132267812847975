.wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(31, 57, 101, 0.5843137254901961);
  z-index: 14;
  display: flex;
  align-items: flex-end;
}
.sliderCard {
  position: relative;
  background-color: var(--tm-color-wh-1);
  height: 90%;
  width: 100%;
  border-radius: 24px 24px 0 0;
}
.closeBtn {
  display: none;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 40px;
  left: -16px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 3px 18px rgb(192 201 253 / 16%);
  background-color: var(--tm-color-wh-1);
  border-radius: 100%;
}
.closeBtnIcon {
  width: 16px;
  height: 16px;
}

@media only screen and (min-width: 1024px) {
  .wrapper {
    justify-content: flex-end;
  }
  .sliderCard {
    height: 100%;
    border-radius: 24px 0 0 24px;
  }
  .closeBtn {
    display: flex;
  }
}
