.homeWrapper {
  width: 100vw;
  height: 100vh;
  /* background-color: var(--tm-kr-color-neutrals-grey-20); */
}

.sidebarMobileWrapper {
  display: block;
}

.contentAreaWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.mainAreaWrapper {
  height: 100%;
  display: flex;
  flex-grow: 1;
}

.sidebarLaptopWrapper {
  display: none;
}

.announcementPanelWrapper {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .sidebarMobileWrapper {
    display: none;
  }

  .sidebarLaptopWrapper {
    display: block;
    width: 300px;
    flex-shrink: 0;
  }

  .announcementPanelWrapper {
    display: block;
  }
}
