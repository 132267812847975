html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif !important;
}

a {
  text-decoration: none;
}
a,
a:visited,
a:hover,
a:active {
  color: var(--tm-kr-color-primary-100);
}

:root {
  /* Primary */
  --tm-kr-color-primary-110: #0c85cf;
  --tm-kr-color-primary-100: #1da1f2;
  --tm-kr-color-primary-90: #4db5f5;
  --tm-kr-color-primary-80: #7dc9f8;
  --tm-kr-color-primary-70: #adddfa;
  --tm-kr-color-primary-60: #ddf1fd;

  /* Base */
  --tm-kr-color-base-dark-100: #2f3c51;
  --tm-kr-color-base-light-100: #ffffff;

  /* Neutrals */
  --tm-kr-color-neutrals-grey-100: #212427;
  --tm-kr-color-neutrals-grey-90: #3f3f41;
  --tm-kr-color-neutrals-grey-80: #5b5b5d;
  --tm-kr-color-neutrals-grey-70: #76787a;
  --tm-kr-color-neutrals-grey-60: #949494;
  --tm-kr-color-neutrals-grey-50: #afafb1;
  --tm-kr-color-neutrals-grey-40: #cccccc;
  --tm-kr-color-neutrals-grey-30: #e8e8e8;
  --tm-kr-color-neutrals-grey-20: #f2f2f2;
  --tm-kr-color-neutrals-grey-10: #ffffff;

  /* Semantic */
  --tm-kr-color-semantic-basic-110: #050505;
  --tm-kr-color-semantic-basic-100: #1f1f1f;
  --tm-kr-color-semantic-basic-10: #e9e9e9;

  --tm-kr-color-semantic-info-110: #00538f;
  --tm-kr-color-semantic-info-100: #0072c3;
  --tm-kr-color-semantic-info-10: #e5f6ff;

  --tm-kr-color-semantic-error-110: #ff3333;
  --tm-kr-color-semantic-error-100: #ff6666;
  --tm-kr-color-semantic-error-10: #ffedf0;

  --tm-kr-color-semantic-caution-110: #c88913;
  --tm-kr-color-semantic-caution-100: #ea8804;
  --tm-kr-color-semantic-caution-10: #fdf2e0;

  --tm-kr-color-semantic-success-110: #4a9c52;
  --tm-kr-color-semantic-success-100: #65b66d;
  --tm-kr-color-semantic-success-10: #f0f8f0;

  /* Pastels */
  --tm-kr-color-pastels-feijoa-100: #a8d793;
  --tm-kr-color-pastels-feijoa-50: #d4ebc9;
  --tm-kr-color-pastels-feijoa-10: #e9f5e4;

  --tm-kr-color-pastels-fringy-100: #a7ddc9;
  --tm-kr-color-pastels-fringy-50: #d3eee4;
  --tm-kr-color-pastels-fringy-10: #e9f6f1;

  --tm-kr-color-pastels-melaine-100: #daa6d8;
  --tm-kr-color-pastels-melaine-50: #ecd2eb;
  --tm-kr-color-pastels-melaine-10: #f5e8f5;

  --tm-kr-color-pastels-biloba-100: #b8a7ea;
  --tm-kr-color-pastels-biloba-50: #dbd3f4;
  --tm-kr-color-pastels-biloba-10: #ede9f9;

  --tm-kr-color-pastels-ash-100: #cac2b5;
  --tm-kr-color-pastels-ash-50: #e4e0da;
  --tm-kr-color-pastels-ash-10: #f1efec;

  --tm-kr-color-pastels-calico-100: #e0c091;
  --tm-kr-color-pastels-calico-50: #efdfc8;
  --tm-kr-color-pastels-calico-10: #f7efe3;

  --tm-kr-color-pastels-seapink-100: #f19a8e;
  --tm-kr-color-pastels-seapink-50: #f8ccc6;
  --tm-kr-color-pastels-seapink-10: #fbe5e2;

  --tm-kr-color-pastels-morningGlory-100: #94d2df;
  --tm-kr-color-pastels-morningGlory-50: #c9e8ef;
  --tm-kr-color-pastels-morningGlory-10: #e4f3f7;

  --tm-kr-color-pastels-poloBlue-100: #93b6d7;
  --tm-kr-color-pastels-poloBlue-50: #c9daeb;
  --tm-kr-color-pastels-poloBlue-10: #e4ecf5;

  /* Border Radius */
  --tm-kr-border-radius-s: 4px;
  --tm-kr-border-radius-m: 8px;
  --tm-kr-border-radius-l: 12px;

  /* Spacing */
  --tm-kr-spacing-4xs: 4px;
  --tm-kr-spacing-3xs: 8px;
  --tm-kr-spacing-2xs: 12px;
  --tm-kr-spacing-xs: 16px;
  --tm-kr-spacing-s: 20px;
  --tm-kr-spacing-m: 24px;
  --tm-kr-spacing-l: 32px;
  --tm-kr-spacing-xl: 36px;
  --tm-kr-spacing-2xl: 40px;
  --tm-kr-spacing-3xl: 48px;
  --tm-kr-spacing-4xl: 56px;
  --tm-kr-spacing-5xl: 64px;
}
