.para {
  font-size: 14px;
  line-height: 22px;
}

/* Types */
.light {
  font-weight: 300;
}
.regular {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}

/* Colors */
.secondary {
  color: var(--tm-kr-color-neutrals-grey-70);
}
.error {
  color: var(--tm-kr-color-semantic-error-100);
}
.success {
  color: var(--tm-kr-color-semantic-success-100);
}
