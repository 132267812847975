.button {
  border: none;
  outline: none;
  height: 36px;
  padding: 0 var(--tm-kr-spacing-m); /* 2xl */
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  border-radius: var(--tm-kr-border-radius-m);
  border-width: 1px;
  border-style: solid;
}

/* Category */
.primary {
  background-color: var(--tm-kr-color-primary-100);
  color: var(--tm-kr-color-neutrals-grey-10);
}
.destructive {
  background-color: var(--tm-kr-color-semantic-error-100);
  color: var(--tm-kr-color-neutrals-grey-10);
}
.constructive {
  background-color: var(--tm-kr-color-semantic-success-100);
  color: var(--tm-kr-color-neutrals-grey-10);
}
/* Category Hover */
.primary:hover {
  background-color: var(--tm-kr-color-primary-110);
}
.destructive:hover {
  background-color: var(--tm-kr-color-semantic-error-110);
}
.constructive:hover {
  background-color: var(--tm-kr-color-semantic-success-110);
}

/* Sizes */
.small {
  height: 36px;
}
.medium {
  height: 40px;
}
.large {
  height: 48px;
}
.extraLarge {
  height: 56px;
}

/* Type Outline */
.primary.outline {
  background-color: var(--tm-kr-color-neutrals-grey-10);
  color: var(--tm-kr-color-primary-100);
  border-color: var(--tm-kr-color-primary-100);
}
.destructive.outline {
  background-color: var(--tm-kr-color-neutrals-grey-10);
  color: var(--tm-kr-color-semantic-error-100);
  border-color: var(--tm-kr-color-semantic-error-100);
}
.constructive.outline {
  background-color: var(--tm-kr-color-neutrals-grey-10);
  color: var(--tm-kr-color-semantic-success-100);
  border-color: var(--tm-kr-color-semantic-success-100);
}
/* Type Hover */
.primary.outline:hover {
  color: var(--tm-kr-color-primary-110);
  border-color: var(--tm-kr-color-primary-110);
}
.destructive.outline:hover {
  color: var(--tm-kr-color-semantic-error-110);
  border-color: var(--tm-kr-color-semantic-error-110);
}
.constructive.outline:hover {
  color: var(--tm-kr-color-semantic-success-110);
  border-color: var(--tm-kr-color-semantic-success-110);
}

/* Type Text */
.primary.text {
  background-color: var(--tm-kr-color-neutrals-grey-10);
  color: var(--tm-kr-color-primary-100);
  border-color: var(--tm-kr-color-neutrals-grey-10);
}
.destructive.text {
  background-color: var(--tm-kr-color-neutrals-grey-10);
  color: var(--tm-kr-color-semantic-error-100);
  border-color: var(--tm-kr-color-neutrals-grey-10);
}
.constructive.text {
  background-color: var(--tm-kr-color-neutrals-grey-10);
  color: var(--tm-kr-color-semantic-success-100);
  border-color: var(--tm-kr-color-neutrals-grey-10);
}
/* Type Hover */
.primary.text:hover {
  color: var(--tm-kr-color-primary-110);
}
.destructive.text:hover {
  color: var(--tm-kr-color-semantic-error-110);
}
.constructive.text:hover {
  color: var(--tm-kr-color-semantic-success-110);
}

/* Disabled */
.disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

/* Width */
.fitContent {
  width: fit-content;
}
.full {
  width: 100%;
}
