.wrapper {
  width: 100%;
  background-color: var(--tm-color-wh-1);
  border-radius: 20px;
}

.header {
  padding: 20px;
  border-bottom: 1px solid var(--tm-color-gy-3);
}

.headerDesc {
  margin-top: 8px;
}
