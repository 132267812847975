.submitButton {
  margin-top: 20px;
  width: 100%;
  height: 40px;
}
.inputWrapper > div {
  padding: 0 0 24px 0;
}
.coursePrefixToggleWrapper {
  background-color: var(--tm-color-gy-3);
  padding: 16px;
  border-radius: 16px;
}
.headingWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.coursePrefixInputWrapper > div {
  padding: 20px 0 10px 0;
}
