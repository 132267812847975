.wrapper {
  display: flex;
  overflow-x: auto;
  border-bottom: 1px solid var(--tm-color-gy-3);
}

.item {
  width: fit-content;
  margin-right: 24px;
  padding: 8px 0 12px 0;
  cursor: pointer;
  white-space: nowrap;
}

.itemSelected {
  color: var(--tm-color-bl-2);
  border-bottom: 1px solid var(--tm-color-bl-2);
}
